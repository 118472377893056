import Drawer from "react-modern-drawer";
import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";

const ResearchOptions = ({ open, onClose }) => {
    return (
        <Drawer
            open={open}
            onClose={onClose}
            direction="right"
            size="365px"
            style={{ border: "1px solid #2895EB", top: "100px", height: "auto" }}
        >
            <Box className="drawer-title">Research Options</Box>
            <Box
                sx={{
                    py: 1,
                    pl: 2,
                    display: "flex",
                    flexDirection: "row", // To align items horizontally
                    justifyContent: "space-between",
                }}
                className="box-btns"
            >
                <a
                    href="http://13.210.207.154:8015"  // Include https:// to ensure it opens in a new tab
                    target="_blank"
                    rel="noopener noreferrer"  // Adds security against potential phishing
                >
                    <button className="btn-btn-research">Research with AI</button>
                </a>
                <a
                                    href="http://logicalenglish.accziom.com"  // Include https:// to ensure it opens in a new tab
                                    target="_blank"
                                    rel="noopener noreferrer" >
                    <button className="btn-btn-research">Research with Logical English</button>
                </a>
            </Box>
            {/* <HierrachyTree selectedIds={selectedIds} setSelectedIds={setSelectedIds} setSelectedPaths={setSelectedPaths} hierarchy={hierarchy}/> */}
        </Drawer>
    );
};

export default ResearchOptions;
