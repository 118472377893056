import Drawer from "react-modern-drawer";
import { Box, TextField } from "@mui/material";
import HierrachyTree from "./HierrachyTree";
import { useEffect, useState, useCallback } from "react";

const DataSource = ({ 
  open, 
  onClose, 
  dataSource, 
  setDataSource, 
  selectedIds, 
  setSelectedIds, 
  setSelectedPaths, 
  hierarchy 
}) => {
  const [searchText, setSearchText] = useState(""); // State for user input
  const [debouncedSearchText, setDebouncedSearchText] = useState(""); // State for debounced value

  // Debounce function
  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // Debounced handler
  const handleDebouncedChange = useCallback(
    debounce((value) => {
      console.log(value)
      setDebouncedSearchText(value);
    }, 500), // Adjust debounce time as needed
    []
  );

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchText(value); // Update immediate input
    handleDebouncedChange(value); // Trigger debounce
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      direction="right"
      size="365px"
      style={{ border: "1px solid #2895EB", top: "100px", height: "auto" }}
    >
      <Box className="drawer-title">ATO Database</Box>

      {/* Search Field */}
      <Box sx={{ padding: "10px" }}>
        <TextField
          fullWidth
          label="Search Data Source"
          variant="outlined"
          value={searchText}
          onChange={handleSearchChange}
          size="small"
        />
      </Box>

      {/* Hierarchy Tree Component */}
      <HierrachyTree 
        selectedIds={selectedIds} 
        setSelectedIds={setSelectedIds} 
        setSelectedPaths={setSelectedPaths} 
        hierarchy={hierarchy} 
        searchText={debouncedSearchText} // Pass debounced search text to HierarchyTree
      />
    </Drawer>
  );
};

export default DataSource;
