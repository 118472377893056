import React from "react";
import MarkdownPreview from "@uiw/react-markdown-preview";

import "../../../styles/components/chat/Content.css";
import ChatFeedBack from "./ChatFeedback";

const SummarizedMsg = ({ answerMsg }) => {
  return (
    <>
      <div className="chat-content-ai">
        <MarkdownPreview
          className="chat-content-summary"
          source={answerMsg.msg}
          wrapperElement={{
            "data-color-mode": "light",
          }}
        ></MarkdownPreview>
        {/* <ChatFeedBack promptId={answerMsg.id} /> */}
      </div>
    </>
  );
};

export default SummarizedMsg;
