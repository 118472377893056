import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useSearchParams } from "react-router-dom";
import { publish } from "../../event/event";

import "react-toastify/dist/ReactToastify.css";
import "react-modern-drawer/dist/index.css";

import "../../styles/global.css";
import "../../styles/layout/chat/ChatPage.css";

import Sidebar from "../../components/chat/Sidebar/Sidebar";
import ChatContent from "../../components/chat/Content/ChatContent";
import ChatInput from "../../components/chat/Content/ChatInput";

import AnswerMessage from "../../components/chat/Content/AnswerMessage";
import { SysMessage } from "../../components/chat/Content/SysMessage";
import SummarizedMsg from "../../components/chat/Content/SummarizedMsg";
import trees from "../../tree.json";

import {
  API_BASE_URL,
  ROLE_USER,
  ROLE_ASSISTANT,
  ROLE_KNOWLEDGE,
} from "../../utils/const";

const ChatPage = ({ container, setContainer, dataSource, selectedPaths, model, hierarchy, legalInfo, setSelectedText, fastSearch,toolTip, setSelectedIds, setSelectedPaths, setHierarchy ,setDataSource ,setFastSearch ,setModel, setDefaultSearch, setLegalInfo, setToolTip, helpGuides }) => {
  const textareaRef = useRef(null);
  const containerRef = useRef(null);
  const chatlistcontainerRef = useRef(null);

  const termsAgree = localStorage.getItem("isTermsAgree");
  const [isTermsAgree, setIsTermsAgree] = useState(
    termsAgree ? termsAgree : false
  );
  const [chatTitle, setChatTitle] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [canEdit, setCanEdit] = useState(true);
  const [chatText, setChatText] = useState("");
  const [messages, setMessages] = useState([]);
  const [chatLists, setChatLists] = useState([]);
  const [chatContents, setChatContents] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);

  const [isThinking, setIsThinking] = useState(false);
  const [isLearnActive, setIsLearnActive] = useState(false);
  const [isSendBtnActive, setIsSendBtnActive] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [link,setLinkCopied] = useState(false)
  const [canChat, setCanChat] = useState(true)

  // Get a specific query parameter

  useEffect(() => {

    console.log(selectedChat, "Selected Chat")
    // setSelectedChat(null)
    if (!searchParams.get('chatId')) {
      newChatClicked()
      const postData = { username: "user", fastSearch:null };

      if(searchParams.get('fastSearch')){
        postData.fastSearch=searchParams.get('fastSearch')
      }
      getChatListFromSever(postData);
    }
    
  }, []);

  useEffect(() => {
    containerRef.current.scrollTop = containerRef.current.scrollHeight;
    if (
      chatContents.length == 0 ||
      selectedChat == 0 ||
      (chatContents.length == 1 && chatContents[0].props.children.length == 0)
    ) {
      setChatTitle("New chat");
    }
  }, [chatContents]);

  useEffect(() => {
    console.log("Hello use Effect in")
    refreshChatList();
  }, [selectedChat, messages]);

  useEffect(() => {
    if ((selectedChat == 0 || selectedChat == null ) && !searchParams.get('chatId')) {
      return;
    }
    console.log("Chat Id", searchParams.get('chatId') )
    const postData = { username: "user", chat_id: selectedChat?selectedChat:searchParams.get('chatId') };
    getChatContentFromServer(postData);
  }, [selectedChat]);

  useEffect(() => {
    if (isTermsAgree) {
      localStorage.setItem("isTermsAgree", true);
    }
  }, [isTermsAgree]);

  // useEffect(() => {
  //   chatlistcontainerRef.current.scrollTop = chatlistcontainerRef.current.scrollHeight;
  // }, [chatLists]);

  const sidebar = useCallback (()=>{

    console.log("hello sidebar")
    console.log(container, chatlistcontainerRef)
    console.log("is loading", isLoading)

    return <Sidebar
    container={container}
    setContainer={setContainer}
    chatlistcontainerRef={chatlistcontainerRef}
    chatLists={chatLists}
    newChatClicked={newChatClicked}
    isLoading={isLoading}
  />
  },[chatLists])

  useEffect ( ()=>{
    console.log(isLoading)

  },[isLoading])


  useEffect (()=>{

    console.log("Component Loaded")
  },[])

  const refreshChatList = () => {
    
    console.log("hello")
    const newChatLists = messages.map((item) => (
      <React.Fragment key={`chatList_${item.id}}`}>
        {item.datetime && (
          <div className="chat-history-date">{item.datetime}</div>
        )}
        <div
          className={
            selectedChat != item.id
              ? "chat-history-body"
              : "chat-history-body active"
          }
        >
          <span
            className="chat-history-body-link"
            onClick={() => chatSelected(item.id, item.title)}
          >
            {item.title}
          </span>
          {/* <button className="button-del" onClick={() => delClicked(item.id, item.title)}>
            <FontAwesomeIcon icon={faTrashCan} size="lg" />
          </button> */}
        </div>
      </React.Fragment>
    ));

    setChatLists([
      <React.Fragment key="chatLists">{newChatLists}</React.Fragment>,
    ]);
  };

  useEffect(()=>{

    console.log(chatLists, "Chat Lists")

  },[chatLists])

  const getSummary = async (_url, title, source) => {
    setChatContents((prevComponents) => [
      ...prevComponents,
      <SysMessage
        key={`sysMessage1_${new Date().getTime()}`}
        initialStatus={true}
        chatMsg={"Summarizing '" + title + "' ..."}
      />,
    ]);
    const postData = { url: _url, title: title, dataSource: source == "weaviate" ? "ato" : source };
    await axios
      .post(`${API_BASE_URL}/chat/get-summary`, postData)
      .then((response) => {
        const responseKey = `responseBlock_${new Date().getTime()}`;
        const answerKey = `answerMessage_${new Date().getTime()}`;

        setChatContents((prevComponents) => [
          ...prevComponents,
          <React.Fragment key={responseKey}>
            <SummarizedMsg
              key={answerKey}
              answerMsg={{
                msg: response.data.summarization,
              }}
              getSummary={getSummary}
            />
          </React.Fragment>,
        ]);
      })
      .catch((error) => toast.error("Error fetching data:" + error))
      .finally(() => {
        textareaRef?.current?.focus();
        publish("endLoading");
        hideSysMsgPromise();
      });
  };

  const fetchCode = async ()=>{

    setLinkCopied(true)
    await axios
    .post(`${API_BASE_URL}/chat/get-chat-link`, {chatId:selectedChat})
    .then((response) => {
      copyCode(response?.data?.link)

    })
    .catch((error) => toast.error("Error fetching data:" + error))
    .finally(() => {
      textareaRef?.current?.focus();
      publish("endLoading");
      hideSysMsgPromise();
    });
  }

  const copyCode = async (text) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Link copied to clipboard!');

      setTimeout(()=>{
        setLinkCopied(false)
      },4000)
    }).catch((err) => {
      console.error('Failed to copy text: ', err);
    });
  }
  const getChatListFromSever = async (postData) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${API_BASE_URL}/chat/get-chat-list`,
        postData
      );
      setCanEdit(true);
      const activeId = response.data.active_id;

      setMessages(response.data.message);
      // setSelectedChat(activeId);

      //refreshChatList(response, activeId);
      textareaRef?.current?.focus();
      setIsLoading(false);
    } catch (error) {
      toast.error("Error fetching chat list: " + error);
    }
  };

  const getChatContentFromServer = async (postData) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/chat/get-chat-content`,
        postData
      );
      let messageString = response.data.message;
      setCanEdit(false);
      const listChatContents = messageString.map((item) => (
        <React.Fragment key={`chatContent_${item?.id}_${new Date().getTime()}`}>
          {item?.role === ROLE_USER || item?.role === ROLE_KNOWLEDGE ? (
            <div className="chat-content-user">{item?.prompt}</div>
          ) : item.role === ROLE_ASSISTANT ? (
            <AnswerMessage
              answerMsg={{
                id: item?.id,
                prompt: item?.prompt,
                metainfo: item?.metainfo,
              }}
              setChatText={setChatText}
              getSummary={getSummary}
            />
          ) : (
            <></>
          )}
        </React.Fragment>
      ));
      setChatContents([
        <React.Fragment key="ChatContents">{listChatContents}</React.Fragment>,
      ]);
      textareaRef?.current?.focus();
    } catch (error) {
      toast.error("Error fetching chat list: " + error);
    }
  };

  const handleChatInputKeyDown = (e) => {
    // if (e.key === "Enter" && e.ctrlKey) {
    //   e.preventDefault();
    //   sendClicked();
    // }
  };

  const process_table = (text) => {
    let lines = text.split("\n");
    lines = lines.map((e) => e.split("\t"));
    let i = 0;
    while (i < lines.length) {
      let ll = lines[i];

      if (ll.length === 1) {
        lines[i] = lines[i][0];
        i += 1;
        continue;
      }
      let j = i + 1;
      while (j < lines.length) {
        if (lines[j].length !== ll.length) break;
        j += 1;
      }
      if (j > i + 1) {
        let cnt = ll.length;
        lines[i] = "| " + lines[i].join(" | ") + " |\n";
        lines[i] += "| " + "--- | ".repeat(cnt);
        for (i = i + 1; i < j; i++) {
          lines[i] = "| " + lines[i].join(" | ") + " |";
        }
        continue;
      } else {
        lines[i] = lines[i].join("\t");
        i += 1;
        continue;
      }
    }
    return lines.join("\n");
  };

  const handleChatTextChange = (event) => {
    let text = process_table(event.target.value);
    setChatText(text);
    if (event.target.value == "") setIsSendBtnActive(false);
    else setIsSendBtnActive(true);
  };

  const sendClicked = () => {
    let isNewChat = false;

    if (chatText === "") {
      return;
    }

    if (
      chatLists.length === 0 ||
      selectedChat === 0 ||
      (chatLists.length === 1 && chatLists[0].props.children.length == 0)
    )
      isNewChat = true;

    // Api Call
    const newFragments = chatText.split("\n").map((line, index) => (
      <div key={`userMessage_${index}`}>
        {line}
        <br />
      </div>
    ));

    setChatContents((prevComponents) => [
      ...prevComponents,
      <React.Fragment key={`userMessage_${new Date().getTime()}`}>
        <div className="chat-content-user">{newFragments}</div>
      </React.Fragment>,
    ]);
    setIsSendBtnActive(false);
    getResponseInformationFromServer(isNewChat);
    setChatText("");
    setIsThinking(true);
  };

  const getResponseInformationFromServer = (isNewChat, message = "") => {
    console.log(dataSource, "Data Source")
    console.log(selectedPaths)
    const postData = {
      username: "user",
      prompt: message === "" ? chatText : message,
      learn: isLearnActive,
      dataSource: dataSource == "weaviate" ? "ato" : dataSource,
      hierrachy: hierarchy == false ? selectedPaths : ["Company tax return instructions 2023"],
      model: model,
      toolTip:toolTip==null || toolTip=='false'? false:true,
      legalInfo: legalInfo=='true'?true:false,
      fastSearch:fastSearch,
      helpGuides: helpGuides=="true"?true:false
    };
    axios
      .post(`${API_BASE_URL}/chat/get-search-data`, postData)
      .then((response) => {
        publish("endLoading");

        if (response.data.message === "success") {
          getResponseFromServer(isNewChat);
        }
      })
      .catch((error) => {
        toast.error("Error fetching data:" + error);
        publish("endLoading");
        setIsThinking(false);
      })
      .finally(() => {
        setIsLearnActive(false);
      });
  };

  const getResponseFromServer = (isNewChat) => {
    const postData = {
      username: "user",
      prompt: chatText,
      learn: isLearnActive,
      hierrachy: selectedPaths,
    };
    axios
      .post(`${API_BASE_URL}/chat/get-response-message`, postData)
      .then((response) => {
        if (!isLearnActive) {
          const responseKey = `responseBlock_${new Date().getTime()}`;
          const answerKey = `answerMessage_${new Date().getTime()}`;

          setChatContents((prevComponents) => [
            ...prevComponents,
            <React.Fragment key={responseKey}>
              <AnswerMessage
                key={answerKey}
                answerMsg={{
                  id: response.data.id,
                  prompt: response.data.message,
                  metainfo: response.data.metainfo,
                }}
                setChatText={setChatText}
                getSummary={getSummary}
              />
            </React.Fragment>,
          ]);

          if (isNewChat) {
            const newMessage = {
              id: response.data.newChatId,
              title: response.data.title,
            };
            messages.unshift(newMessage);
            const newChatId = response.data.newChatId;
            setSelectedChat(newChatId);
          }

          hideSysMsgPromise();
        }
      })
      .catch((error) => {
        toast.error("Error fetching data:" + error);
        setIsThinking(false);
      })
      .finally(() => {
        textareaRef?.current?.focus();
        publish("endLoading");
        setIsThinking(false);
      });
  };

  const hideSysMsgPromise = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    publish("hideSysMsg");
  };

  const chatSelected = (chat_id, chat_title) => {
    setSelectedChat(chat_id);
    setChatTitle(chat_title);
    setCanChat(false)
  };

  const delClicked = (chat_id, chat_title) => {
    const postData = { username: "user", chat_id: chat_id };
    axios
      .post(`${API_BASE_URL}/chat/delete-chat`, postData)
      .then((response) => {
        setChatContents([]);
        getChatListFromSever(postData);

        toast.success(`Chat history  '${chat_title}'  removed.`);
      })
      .catch((error) => toast.error("Error fetching data:" + error));
  };

  const learnClicked = () => {
    setIsLearnActive(isLearnActive ? false : true);
  };

  const newChatClicked = () => {
    setChatContents([]);
    const postData = { username: "user" };
    axios
      .post(`${API_BASE_URL}/chat/create-new-chat`, postData)
      .then((response) => {
        if (response.data.message === "success") {
          setSelectedChat(0);
          setCanChat(true)
        }
      })
      .catch((error) => toast.error("Error fetching data:" + error));

    textareaRef?.current?.focus();
  };
  const reindexTree = (nodes, parents = [], path = "") => {
    return nodes.map((node, index) => {
      const currentId =
        parents && parents.length
          ? `${parents[parents.length - 1]}.${index + 1}`
          : `${index + 1}`;
      const newNode = {
        ...node,
        id: currentId,
        parents: parents,
        path: getLabelTitle(path, node.title),
      };
      const childParent = [...parents, currentId];
  
      if (node.children && node.children.length > 0) {
        newNode.children = reindexTree(node.children, childParent, newNode.path);
      }
      return newNode;
    });
  };

  const getLabelTitle = (parent, title) =>
  parent && parent.length > 0 ? `${parent} > ${title}` : title;
  
  const treeItems = reindexTree(trees);

  useEffect(() => {
    if (!hierarchy) {
      const parents = ["1", "2", "3", "15"];
      setSelectedIds([...parents]);
      const paths = parents.map((parent) => getNodeById(treeItems, parent).path);
      console.log(paths)
      setSelectedPaths([...paths]);
    }
    
  }, [hierarchy]);

  useEffect(()=>{
    setDataSource("azureS")
    setModel("gpt-4o")
    setHierarchy(false)
    setLegalInfo("false")
    setFastSearch(null)
    setToolTip('false')
    setDefaultSearch(true)
  },[])

  const getNodeById = (nodes, id) => {
    for (const node of nodes) {
      if (node.id === id) {
        return node;
      } else if (node.children && node.children.length) {
        const foundChild = getNodeById(node.children, id);
        if (foundChild) {
          return foundChild;
        }
      }
    }
    return null;
  };

  return (
    <div className="chat-container">

      {sidebar()}

      <div className="main-container">
        <ChatContent
          isTermsAgree={isTermsAgree}
          setIsTermsAgree={setIsTermsAgree}
          isThinking={isThinking}
          containerRef={containerRef}
          chatContents={chatContents}
          setChatText={setChatText}
          setSelectedText = {setSelectedText}
        />
      { !searchParams.get('chatId') &&<ChatInput
         chatId = {selectedChat}
          link= {link}
          setLinkCopied={setLinkCopied}
          fetchCode ={fetchCode}
          isTermsAgree={isTermsAgree}
          textareaRef={textareaRef}
          // chatText={chatText}
          isThinking={isThinking}
          canEdit={canEdit}
          handleChatInputKeyDown={handleChatInputKeyDown}
          handleChatTextChange={handleChatTextChange}
          sendClicked={sendClicked}
          learnClicked={learnClicked}
          isSendBtnActive={isSendBtnActive}
          isLearnActive={isLearnActive}
          canChat = {canChat}
        />}
      </div>
    </div>
  );
};

export default ChatPage;
