import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Box } from "@mui/material";

import Header from "../components/header/Header";
import BrowsePage from "./browse/BrowsePage";
import ChatPage from "./chat/ChatPage";
import "../styles/layout/Home.css";
import DataSource from "./drawer/DataSource";
import SearchOption from "./drawer/SearchOption";
import Tooltip from '@mui/material/Tooltip';
import SpecificSearch from "./drawer/SpecificSearch";
import { useSearchParams } from "react-router-dom";
import LegalInfo from "./drawer/LegalInfo";
import ScratchPadPage from "./scratch-pad/ScratchPadPage";
import AdvancedSearch from "./drawer/AdvancedSearch";
import HelpGuides from "./drawer/HelpGuides";
import ResearchOptions from "./drawer/ResearchOptions";
import ReverseSearch from "./reverse-search/ReverseSearch";
const Home = () => {
  const tabName = localStorage.getItem("tabName");

  // const [isPreLoading, setIsPreLoading] = useState(true);
  const [container, setContainer] = useState(tabName ? tabName : "Chat");

  const [isConfigOpen, setConfigOpen] = useState(false);
  const [dataSource, setDataSource] = useState("azureS");
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedPaths, setSelectedPaths] = useState([]);
  const [model, setModel] = useState("gpt-4o");
  const [hierarchy, setHierarchy] = useState(false)
  const [legalInfo, setLegalInfo] = useState("false")
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedText, setSelectedText] = useState('')
  const [scratchText, setScratchText] = useState('')
  const [defaultSearch, setDefaultSearch] = useState(true)
  const [defaultSearchOpen, setDefaultSearchOpen] = useState(false)
  const [fastSearch, setFastSearch] = useState(null)
  const [toolTip, setToolTip] = useState('false');
  const [helpGuides, setHelpGuides] = useState('true')
  const [helpGuidesOpen, setHelpGuidesOpen] = useState(false)
  const [researchOptions, setResearchOptions] = useState(false)

  let tempSelectedText = ''
  useEffect(() => {
    localStorage.setItem("tabName", container);

  }, [container]);


  useEffect(() => {
    const fastSearchParam = searchParams.get("fastSearch");

    if (fastSearchParam) {
      // Decode the URL-encoded fastSearch parameter
      const decodedFastSearch = decodeURIComponent(fastSearchParam);
      setDataSource(null)
      setDefaultSearch(false)

      // Set the decoded value using setFastSearch
      setFastSearch(decodedFastSearch);
    }
  }, []);

  useEffect(() => {
    if (selectedText) {
      tempSelectedText = selectedText
    }
    console.log("Selected Text", selectedText)
  }, [selectedText])


  const addToScratch = () => {
    console.log(tempSelectedText, "Selected Text");
    tempSelectedText += "\n"
    console.log(tempSelectedText, "HEllo")
    setScratchText((prev) => {
      return prev + '\n' + tempSelectedText
    });

    setSelectedText('');
  }


  return (
    <> <div className="container">
      <Header />
      <Box className="container-box">
        {container === "Chat" && <ChatPage container={container} setContainer={setContainer} dataSource={dataSource} selectedPaths={selectedPaths} model={model} hierarchy={hierarchy} legalInfo={legalInfo} setSelectedText={setSelectedText} fastSearch={fastSearch} toolTip={toolTip} setSelectedIds={setSelectedIds} setSelectedPaths={setSelectedPaths} setHierarchy={setHierarchy} setDataSource={setDataSource} setFastSearch={setFastSearch} setModel={setModel} setDefaultSearch={setDefaultSearch} setLegalInfo={setLegalInfo} setToolTip={setToolTip} helpGuides = {helpGuides}/>}
        {container === "Browse" && <BrowsePage container={container} setContainer={setContainer} dataSource={dataSource} selectedPaths={selectedPaths} setSelectedIds={setSelectedIds} setSelectedPaths={setSelectedPaths} hierarchy={hierarchy} setHierarchy={setHierarchy} setDataSource={setDataSource} setFastSearch={setFastSearch} setModel={setModel} setDefaultSearch={setDefaultSearch} setLegalInfo={setLegalInfo} setToolTip={setToolTip} />}
        {container === "ScratchPad" && <ScratchPadPage container={container} setContainer={setContainer} dataSource={dataSource} selectedPaths={selectedPaths} model={model} hierarchy={hierarchy} legalInfo={legalInfo} selectedText={scratchText} />}
        {container==='ReverseSearch' && <ReverseSearch container={container} setContainer={setContainer} selectedPaths={selectedPaths}/>}

      </Box>

      { searchParams.get('fastSearch') ? <> <Box>
        
     
      </Box>

      </>:""}

      <HelpGuides helpGuides={helpGuides} setHelpGuides={setHelpGuides} helpGuidesOpen={helpGuidesOpen} setHelpGuidesOpen={()=>{setHelpGuidesOpen(false)}} ></HelpGuides>
      {!searchParams.get('chatId') && !searchParams.get('fastSearch') && container != 'ScratchPad' ? <> <Box>
        <Tooltip
          title={dataSource === 'google' && container != 'Browse' ? 'Data sources not available during google and fast search' : ''}
          placement="top" // Adjust the placement as needed
        >
          <button
            onClick={() => setConfigOpen(!isConfigOpen)}
            disabled={(dataSource === 'google' || hierarchy || fastSearch != null) && container != 'Browse'}
            className={dataSource === 'google' || hierarchy || fastSearch != null ? " btn-btn-grey" : "btn-data-source"}
          >
            DATA SOURCES
          </button>
        </Tooltip>
      </Box>
        <DataSource open={isConfigOpen} onClose={() => setConfigOpen(false)} dataSource={dataSource} setDataSource={setDataSource} selectedIds={selectedIds} setSelectedIds={setSelectedIds} setSelectedPaths={setSelectedPaths} hierarchy={hierarchy} />
        {container === "Chat" && <Box>

          <button className="btn-search-options" onClick={() => {
            setDefaultSearchOpen(!defaultSearchOpen)
          }}>
            ADVANCED SEARCH
          </button>
        </Box>}
        {container === "Chat" && <AdvancedSearch
          open={defaultSearchOpen}
          dataSource={dataSource}
          setDataSource={setDataSource}
          model={model}
          setModel={setModel}
          defaultSearch={defaultSearch}
          setDefaultSearch={setDefaultSearch}
          hierarchy={hierarchy}
          setHierarchy={setHierarchy}
          legalInfo={legalInfo}
          setLegalInfo={setLegalInfo}
          fastSearch={fastSearch}
          setFastSearch={setFastSearch}
          setSelectedIds={setSelectedIds}
          setSelectedPaths={setSelectedPaths}
          toolTip={toolTip}
          setToolTip={setToolTip}
          onClose={() => {
            setDefaultSearchOpen(false);
          }}
        />}


    



        {container === 'Chat' && (<Box>

          <button className="btn-model-options" onClick={() => {
            setResearchOptions(true)
          }}>
            RESEARCH OPTIONS
          </button>
        </Box>)}

        {
          container==='Chat' && (<Box>
            <ResearchOptions open={researchOptions} onClose={()=>{setResearchOptions(false)}}/>
            </Box>
            )
        }




        {/* {container === 'Chat' && (<Box>
          <Tooltip
            title={dataSource === 'google' ? 'Specific not available during google search' : ''}
            placement="top" // Adjust the placement as needed
          >
            <button className={dataSource !== 'google' ? "btn-specific-search" : "btn-btn-grey-specific"} disabled={dataSource === 'google'} onClick={() => {
              setHierarchyOpen(prev => !prev)

            }}>
              SPECIFIC SEARCH
            </button>
          </Tooltip>
        </Box>)}
        <SpecificSearch open={hierarchyOpen} onClose={() => {
          setHierarchyOpen(false); setModelOpen(false)
        }} hierarchy={hierarchy} setHierarchy={setHierarchy} /> */}

        {/* {container === 'Chat' && (<Box>
          <Tooltip
            title={dataSource === 'google' ? 'Legal Information not available during google search' : ''}
            placement="top" // Adjust the placement as needed
          >
            <button className={dataSource !== 'google' ? "btn-legal-info" : "btn-btn-grey-legal"} disabled={dataSource === 'google'} onClick={() => {
              setLegalInfoOpen(prev => !prev)

            }}>
              LEGAL INFO
            </button>
          </Tooltip>
        </Box>)} */}
        {/* <LegalInfo open={legalInfoOpen} onClose={() => {
          setLegalInfoOpen(false); setModelOpen(false)
        }} legalInfo={legalInfo} setLegalInfo={setLegalInfo} />*/}

        {
          container === 'Chat' && selectedText && (
            <button className="btn-btn-scratch-pad" onClick={addToScratch}>ADD TO SCRATCH PAD</button>
          )
        }

      </> : ""}




      <ToastContainer position="top-right" autoClose={1000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
    </div></>
  );
};

export default Home;
