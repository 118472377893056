import React, { useEffect, useState } from "react";
import "../../../styles/components/chat/Content.css";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { getTitle, getHierrachy } from "../../../utils/utils";
import HPInterestRate from "../../calculator/HPInterestRate";
import HPAnalyzer from "../../calculator/HPAnalyzer";
import Superannuation from "../../calculator/Superannuation";

import { ReactComponent as Workspaces } from "../../../images/workspaces.svg";
import { ReactComponent as ArrowForward } from "../../../images/arrow-forward.svg";
import { Tooltip } from "@mui/material";
import PromptExamples from "./PromptExamples";
import ChatFeedBack from "./ChatFeedback";
import LoanDetails from "../../calculator/LoadDetails";
import Checkbox from "@mui/material/Checkbox";



const ReferencesContainer = ({ dataSource, references, selectedReferences, setSelectedReferences,getSummary}) => {


  console.log(getSummary, "get Summary")
  console.log(selectedReferences, "References in reference container")
  const [refInfo, setRefInfo] = useState(null);

  const formatUrls = (references) => {
    if (dataSource === "google" || dataSource === "google_com") {
      return references; // Direct return for Google data sources
    }

    if (Array.isArray(references)) {
      return references
    }

    const data = {};

    // Loop through the keys of the reference object (text_search_document, semantic_search_document, etc.)
    Object.entries(references || {}).forEach(([key, referenceList]) => {
      for (const reference of referenceList || []) {
        // Split the hierarchy if it exists
        const titleArray = reference?.hierarchy?.split(" > ");
        if (titleArray) {
          // Assign the first part or second part of hierarchy to subtitle based on condition
          const subtitle =
            titleArray[0] === "ATO general website" ? titleArray[1] : titleArray[0];

          // Ensure we create a new array for each subtitle
          if (!data[key]) {
            data[key] = [];
          }

          // Push formatted data into the array under the correct subtitle
          data[key].push({
            ...reference,
            title: getTitle(reference.hierarchy), // Get title using existing logic
            hierarchy: getHierrachy(reference.hierarchy), // Get hierarchy using existing logic
          });
        }
      }
    });

    return data;
  };

  useEffect(() => {
    console.log(references, "References")
    if (references) {
      console.log("References before formatting:", references);
      const formattedData = formatUrls(references);
      console.log("Formatted References:", formattedData);
      setRefInfo(formattedData);
    }
  }, [references]);

  useEffect(() => {
    console.log("RefInfo updated:", refInfo);
  }, [refInfo]);

  return (

    <div>
      {Array.isArray(refInfo) ? (
        refInfo?.map((reference, index) => (
          <div key={index} className="chat-content-ai">
            {/* Render the string or wrap it in appropriate tags */}
            <MarkdownPreview
              className="chat-content-ai-text"
              source={reference}
              wrapperElement={{
                "data-color-mode": "light",
              }}
            />
          </div>
        ))
      ) : (
        <div className="chat-content-references-container">
          <div
            className="chat-content-references-header"
            style={{ marginBottom: dataSource === "google" ? "12px" : "0px" }}
          >
            Results
          </div>
          <div className="chat-content-references-content">
            {Object.entries(refInfo || {}).map(([title, refs], index) => (
              <div key={index}>
                <AtoReferenceItem
                  object_key={title}
                  title={
                    title === "text_search_document"
                      ? "Tax Search Document"
                      : "Semantic Search Document"
                  }
                  references={refs}
                  dataSource={dataSource === "weaviate" ? "ato" : "azureS"}
                  setSelectedReferences={setSelectedReferences}
                  selectedReferences={selectedReferences}
                  getSummary={getSummary}

                />
                {index !== Object.keys(refInfo).length - 1 && (
                  <div className="chat-content-reference-divider"></div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>

  );
};







const AtoReferenceItem = ({ object_key, title, references, dataSource, selectedReferences, setSelectedReferences,getSummary}) => {
  console.log(selectedReferences, "Selected References");

  const [isSummarizing, setIsSummarizing] = useState(false);

  const [selectedRef, setSelectedRef] = useState([])

  // Handle the summarizing logic (currently commented out)
  const handleSummary = async (reference) => {

    console.log(getSummary)
    setIsSummarizing(true);
   await getSummary(reference.url, reference.title, dataSource);
    setIsSummarizing(false);
  };

  // Handle opening the reference URL in a new tab
  const handleOpen = (reference) => {
    window.open(reference.url, "_blank");
  };


  // Log selected references whenever they change
  useEffect(() => {
    console.log("Updated selectedReferences:", selectedReferences);
  }, []);

  // Handle checkbox selection/deselection
  const handleCheckboxChange = (reference, category) => {
    console.log("Current selectedReferences (before update):", selectedReferences); // Logs the current state
  

    setSelectedRef((prev) => {
      if (prev.includes(reference.num)) {
          // Remove the reference.num if it's already selected
          return prev.filter((num) => num !== reference.num);
      } else {
          // Add the reference.num if it's not selected
          return [...prev, reference.num];
      }
  });
    setSelectedReferences((prev) => {
      console.log("Previous State (prev):", prev); // Logs the most accurate current state at the time of update
  
      const updatedCategory = (prev[category] || []).includes(reference.num)
        ? (prev[category] || []).filter((num) => num !== reference.num) // Remove if already selected
        : [...(prev[category] || []), reference.num]; // Add if not selected
  

      let temp =  {
        ...prev,
        [category]: updatedCategory, // Update only the category being modified
      };

      console.log(temp)
      console.log("Updated Category:", updatedCategory);
      
      return temp
    });
  };
  



  return (
    <>
      <div className="chat-content-refernce-sub-title">{title}</div>
      <div className="chat-content-referencs">
        {references.length > 0 &&
          references.map((reference, index) => (
            <div className="chat-content-refernce-item" key={index}>
              <div className="chat-content-reference-hierrachy">

                <span>{reference.num}</span>
                <Checkbox
                
                  checked={selectedRef?.includes(reference.num)}
                  onChange={() => handleCheckboxChange(reference, object_key)}
                  color="primary"
                />

                {reference.hierarchy}
              </div>
              <div className="chat-content-reference-button-group">
                <Tooltip title="Summarize" placement="top">
                  <button
                    className="chat-content-reference-button"
                    onClick={() => handleSummary(reference)}
                    disabled={isSummarizing}
                  >
                    <Workspaces />
                  </button>
                </Tooltip>
                <Tooltip title={reference.url} placement="top">
                  <button
                    className="chat-content-reference-button"
                    onClick={() => handleOpen(reference)}
                  >
                    <ArrowForward />
                  </button>
                </Tooltip>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};



const GoogleReferenceItem = ({ reference, dataSource }) => {
  const [isSummarizing, setIsSummarizing] = useState(false);

  const handleSummary = async (ref) => {
    // setIsSummarizing(true);
    // await getSummary(ref.url, ref.title, dataSource);
    // setIsSummarizing(false);
  };
  const handleOpen = (ref) => {
    window.open(ref.url, "_blank");
  };

  return (
    <div className="chat-content-refernce-item">
      <div className="chat-content-reference-hierrachy">{reference.title}</div>
      <div className="chat-content-reference-button-group">
        <Tooltip title="Summarize" placement="top">
          <button
            className="chat-content-reference-button"
            onClick={() => handleSummary(reference)}
            disabled={isSummarizing}
          >
            <Workspaces />
          </button>
        </Tooltip>
        <Tooltip title={reference.url} placement="top">
          <button
            className="chat-content-reference-button"
            onClick={() => handleOpen(reference)}
          >
            <ArrowForward />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

const AnswerMessage = ({ answerMsg, setChatText, selectedReferences, setSelectedReferences,getSummary}) => {


  useEffect(()=>{
    console.log(answerMsg, "Answer Message")
    console.log(selectedReferences, "Reverences in Answer Message")
    console.log(getSummary, "Summary")
    console.log(setSelectedReferences, "set selected References")
  },[])
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className="chat-content-ai">
        {/* <MarkdownPreview
          className="chat-content-ai-text"
          source={answerMsg?.prompt}
          wrapperElement={{
            "data-color-mode": "light",
          }}
        ></MarkdownPreview> */}
        {/* {answerMsg.metainfo.functionName ===
          "get_hire_purchase_interest_rate" && <HPInterestRate />}
        {answerMsg.metainfo.functionName === "get_hire_purchase_analyzer" && (
          <HPAnalyzer />
        )}
        {answerMsg.metainfo.functionName === "get_superannuation" && (
          <Superannuation />
        )}
        {answerMsg.metainfo.functionName === "calc_division_7a_loan" && (
          <LoanDetails />
        )} */}
        {/* <ChatFeedBack promptId={answerMsg.id} /> */}

        <>
          {answerMsg.metainfo && (
            <ReferencesContainer
              dataSource={"azureS"}
              references={answerMsg.metainfo}
              setSelectedReferences={setSelectedReferences}
              selectedReferences={selectedReferences}
              getSummary={getSummary}

              
            // getSummary={getSummary}
            />
          )}
          {/* {answerMsg.metainfo.prompts &&
              answerMsg.metainfo.prompts.length > 0 && (
                <PromptExamples
                  prompts={answerMsg.metainfo.prompts}
                  setChatText={setChatText}
                />
              )} */}
        </>

      </div>
    </LocalizationProvider>
  );
};

export default AnswerMessage;
