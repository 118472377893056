import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
const SearchOption = ({ dataSource, setDataSource, model, setModel, defaultSearch, handleDefaultSearch, setFastSearch }) => {
    const handleChange = (event) => {
        setFastSearch(null)
        setDataSource(event.target.value);
        if (defaultSearch)
            handleDefaultSearch(false)
    };

    return (

        <>
            <>
                <Box className="drawer-title">Search Option</Box>
                <RadioGroup sx={{ py: 1, pl: 2 }} value={dataSource} onChange={handleChange}>
                    <FormControlLabel value="google" control={<Radio />} label={<Box sx={{ display: 'flex', alignItems: 'center' }}>
                        Google Search&nbsp; <Tooltip title="The Google Search API allows you to access and integrate web search results into your applications effortlessly, providing users with relevant and real-time information, but this is only targeted at the ATO Website"><InfoIcon sx={{ mr: 1 }} /></Tooltip></Box>} />
                    <FormControlLabel value="google_com" control={<Radio />} label={<Box sx={{ display: 'flex', alignItems: 'center' }}>
                        Commentaries&nbsp; <Tooltip title="Commentaries data will search the web and gather data other than the ATO Website, providing you realtime information"><InfoIcon sx={{ mr: 1 }} /></Tooltip></Box>} />
                    <FormControlLabel value="weaviate" control={<Radio />} label={<Box sx={{ display: 'flex', alignItems: 'center' }}>
                        Weaviate&nbsp; <Tooltip title="The Weaviate API helps you store and search information in a smart database that understands the meaning of your data"><InfoIcon sx={{ mr: 1 }} /></Tooltip></Box>} />
                    <FormControlLabel value="azure" control={<Radio />} label={<Box sx={{ display: 'flex', alignItems: 'center' }}>
                        Microsoft Azure Full Text&nbsp; <Tooltip title="Azure Full-Text Search enables efficient storage and retrieval of large text datasets with advanced search capabilities, including filtering, ranking, and linguistic analysis"><InfoIcon sx={{ mr: 1 }} /></Tooltip></Box>} />
                    <FormControlLabel value="azureS" control={<Radio />} label={<Box sx={{ display: 'flex', alignItems: 'center' }}>
                        Microsoft Azure Semantic&nbsp; <Tooltip title="Azure Semantic Search enhances traditional full-text search by understanding the meaning and context of your queries, providing more relevant and accurate results."><InfoIcon sx={{ mr: 1 }} /></Tooltip></Box>} />
                    <FormControlLabel value="azureV" control={<Radio />} label={<Box sx={{ display: 'flex', alignItems: 'center' }}>
                        Microsoft Azure Hybrid&nbsp; <Tooltip title="Combined Semantic and Vectorised Search"><InfoIcon sx={{ mr: 1 }} /></Tooltip></Box>} />


                </RadioGroup>
            </>
            {/* <>
                <Box className="drawer-title">Model Option </Box>

                <RadioGroup
                    sx={{
                        py: 1,
                        pl: 2,
                        display: 'flex',
                        flexDirection: 'row', // To align items horizontally
                        justifyContent: 'flex-start', // To align items at the start
                    }}
                    value={model}
                    onChange={handleModelChange}
                >
                    <FormControlLabel value="gpt-4" control={<Radio />} label="GPT-4" sx={{ mr: 1 }} />
                    <FormControlLabel value="gpt-4o" control={<Radio />} label={<Box sx={{ display: 'flex', alignItems: 'center' }}>
                        GPT-4o &nbsp; <Tooltip title="Switch between models for generating your answers and providing summaries."><InfoIcon sx={{ mr: 1 }} /></Tooltip></Box>} sx={{ mr: 1 }} />
                </RadioGroup>
            </> */}
        </>


    );
};

export default SearchOption;
