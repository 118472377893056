import React, {useState} from "react";
import TextareaAutosize from "react-textarea-autosize";
import "../../../styles/components/chat/ChatInput.css";
import { ArrowForward } from "@mui/icons-material";
// import { ReactComponent as Stop } from "../../../images/stop-circle-bold.svg";
import { CircularProgress } from "@mui/material";
import axios from "axios";

const ChatInput = ({ isThinking, getResponseForRef }) => {


  const [chatText, setChatText] = useState("");


  const setQuery = ($event) => {
    // Update chatText with the input text
    const updatedText = $event.target.value;
    setChatText(updatedText);

    // Check if the pressed key is "Enter"
    if ($event.key === "Enter" && updatedText!='') {
      // $event.preventDefault(); // Prevent the default behavior of form submission
      getResponseForRef(updatedText);
      $event.target.value = ""; // Clear the input field
    }
  };

  const handleSubmit = () =>{
    getResponseForRef(chatText)
  }

  return (
    <>
      {<div className="chat-input">
        <div className="chat-input-box" >
          <TextareaAutosize rows={1} minRows={1} maxRows={9} className="chat-input-textarea-field" placeholder="Ask Tax Genii anything......" onChange={setQuery}/>
        </div>
        {!isThinking && chatText && (
          <button className="chat-input-button-send" onClick={handleSubmit}>
            <ArrowForward />
          </button>
        )}
        {/* <button className="share-chat" onClick={fetchCode} disabled={!chatId || link}>
          {!link ? "Share Chat" : "Link Copied"}
        </button> */}
        {isThinking && (
          <button className="chat-input-button-send" >
            <CircularProgress color="primary" size={30} sx={{ svg: { width: "30px", height: "30px" } }} />
          </button>
        )}
      </div>}
    </>
  );
};

export default ChatInput;
