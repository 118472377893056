import React from "react";

import "../../styles/components/header/ChatTitle.css";
import ImageBanner from "./ImageBanner";
import { ReactComponent as Lodgeit } from "../../images/lodgeit.svg";
import { LODGEIT_URL } from "../../utils/const";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const Header = () => {
  return (
    <div className="chat-title-container">
      <ImageBanner imgPath="images/banner.png" />
      <Button className="btn-new-chat margin-news-btn" onClick={() => window.location.href = '/news'}>
        <span>NEWS</span>
      </Button>
      <div className="btn-lodgeit">
        <span>BACK TO</span>
        <Link to={LODGEIT_URL}>
          <Lodgeit />
        </Link>
      </div>
    </div>
  );
};

export default Header;
